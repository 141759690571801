* {
  outline: none;
}
button:focus {
  outline: none;
}

.MuiInput-underline::before {
  border-bottom: none !important;
}

.MuiInput-underline::before:focus {
  border-bottom: none !important;
}

.MuiInput-underline::before:active {
  border-bottom: none !important;
}

.MuiInput-underline::before:hover {
  border-bottom: none !important;
}

.bg-transparent {
  background: transparent !important;
}

.no-shadow {
  box-shadow: none !important;
}

td > samp > a {
  text-decoration: underline;
  color: rgb(0, 108, 174);
}

iframe {
  display: none !important;
}
